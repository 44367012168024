import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import ErrorPage from "./Component/Error/ErrorPage";
import Inbox from "./Component/FishIn/Inbox";
import Outbox from "./Component/FishOut/Outbox";
import { HomeLayout } from "./Component/HomeLayout/HomeLayout";
import LandingPage from "./Component/LandingPage/LandingPage";
import ProtectedLayout from "./Component/ProtectedLayout/ProtectedLayout";
import FishForm from "./Component/SendFish/FishForm";
import FishSent from "./Component/SendFish/FishSent";
import FishLogin from "./Login/FishLogin";
import FishPasswordLogin from "./Login/FishPasswordLogin";

import Step1 from "./Component/2FAPage/Step1";
import Step2 from "./Component/2FAPage/Step2";
import Step3 from "./Component/2FAPage/Step3";
import Step4 from "./Component/2FAPage/Step4";
import FishPDFViewer from "./Component/Common/FishPDFViewer";
import ChangePW from "./Component/ForgetPassword/ChangePW";
import ForgetPasswordPage from "./Component/ForgetPassword/ForgetPasswordPage";
import PWSuccess from "./Component/ForgetPassword/PWSuccess";
import { Layout } from "./Component/Layout/Layout";
import FALogin from "./Login/FALogin";
function App() {
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(function (config) {
    const tokenSession = sessionStorage.getItem("sessionToken");
    config.headers.Authorization = "Bearer " + tokenSession;

    return config;
  });
  const [isLoggedin, setIsLoggedin] = useState(
    sessionStorage.getItem("login") || false,
  );
  const [locale, setLocale] = useState("en");
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    if (sessionStorage.getItem("login") === "true") {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }

    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, [isLoggedin]);

  useEffect(() => {
    document.title = "Fish"; // Change the title here
  }, []);

  // }, []);
  // const onClickHandler = (e) => {
  //   e.preventDefault();

  // };
  const faqPDFURL = "/fish/FAQonFISHsendingplatforms.pdf";
  return (
    <>
      <div className="background">
        <Router basename={process.env.REACT_APP_ROUTER_BASE}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<FishLogin />} />
            <Route path="/fishPassword" element={<FishPasswordLogin />} />
            <Route path="oktalandingpage.jsp" element={<LandingPage />} />
            <Route path="/login/2fa" element={<FALogin />} />
            <Route element={<Layout />}>
              <Route path="fish/setting-login/2fa1" element={<Step1 />} />
              <Route path="fish/setting-login/2fa2" element={<Step2 />} />
              <Route path="fish/setting-login/2fa3" element={<Step3 />} />
              <Route path="fish/setting-login/2fa4" element={<Step4 />} />
            </Route>
            <Route element={<ProtectedLayout />}>
              <Route path="setpassword.jsp" element={<ForgetPasswordPage />} />
              <Route path="changepassword.jsp" element={<ChangePW />} />
              <Route element={<HomeLayout />}>
                <Route path="inbox" element={<Inbox />} />
                <Route path="sendFish" element={<FishForm />} />
                <Route path="outbox" element={<Outbox />} />
                <Route path="fishSent" element={<FishSent />} />
                <Route path="PWSuccess" element={<PWSuccess />} />
                <Route
                  path="fish/setting/2fa1"
                  element={<Step1 isLogin={true} />}
                />
                <Route
                  path="fish/setting/2fa2"
                  element={<Step2 isLogin={true} />}
                />
                <Route
                  path="fish/setting/2fa3"
                  element={<Step3 isLogin={true} />}
                />
                <Route
                  path="fish/setting/2fa4"
                  element={<Step4 isLogin={true} />}
                />
                <Route
                  path="pdfView/faq"
                  element={<FishPDFViewer pdfURL={faqPDFURL} />}
                />
                <Route path="logout" element={<Navigate to="/login" />} />
              </Route>
            </Route>
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </Router>

        <div></div>
      </div>
    </>
  );
}

export default App;
