import { Pagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/esm/Table";
import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";
import FishInItem from "./FishInItem";
import "./InboxFishList.css";

const InboxFishList = () => {
  var constants;

  if (process.env.REACT_APP_ENV === "local") {
    constants = require("../lib/constant-local");
  } else if (process.env.REACT_APP_ENV === "dev") {
    constants = require("../lib/constant-dev");
  } else if (process.env.REACT_APP_ENV === "uat") {
    constants = require("../lib/constant-uat");
  } else if (process.env.REACT_APP_ENV === "prod") {
    constants = require("../lib/constant-prod");
  } else {
    constants = require("../lib/constant");
  }

  const baseURL = constants.GETINBOX;
  const [fishesIn, setFishesIn] = useState([]);
  const [locale, setLocale] = useState("en");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const sessionToken = sessionStorage.getItem("sessionToken");
  const sessionDetails = {
    username: sessionToken,
    orderBy: "DESC",
    id: 1,
    sortBy: "datetime",
    offset: (page - 1) * constants.ITEM_PER_PAGE,
  };
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
    axios
      .post(baseURL, sessionDetails)
      .then((res) => {
        setFishesIn(res.data);
        setTotalPage(res.data?.[0].totalPage || 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handleChange = (e, page) => {
    setPage(page);
  };

  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Pagination
          style={{
            background: "white",
            position: "relative",
            zIndex: "9999",
            width: "fit-content",
            left: "72%",
          }}
          component="div"
          count={totalPage}
          page={page}
          rowsPerPage={20}
          onChange={handleChange}
        />
        <div style={{ height: "100%", overflow: "auto" }}>
          <Table className="fixed-table">
            <thead>
              <tr>
                <th className="text-center">
                  <FormattedMessage id="date" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="sentby" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="fishnumber" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="message" />
                </th>
              </tr>
            </thead>
            <tbody>
              {fishesIn.map((fishIn) => (
                <FishInItem
                  key={fishIn.id}
                  date={fishIn.date}
                  name={fishIn.name}
                  fishcount={fishIn.fishcount}
                  message={fishIn.message}
                ></FishInItem>
              ))}
            </tbody>
          </Table>
        </div>
        {/* </div> */}
      </IntlProvider>
    </>
  );
};

export default InboxFishList;
